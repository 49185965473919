import React, { FC, useState } from 'react';
import { usePaymentsTranslation } from '../../hooks/usePaymentsTranslation';
import { useAccountManagementStore } from '../../store/AccountManagement';

export const Autopay: FC = () => {
  const { customerUser } = useAccountManagementStore();
  const { t } = usePaymentsTranslation();
  const [view, setView] = useState<'LIST' | 'EDIT'>('LIST');

  if (view === 'LIST') return <>Autopay Enrollment List Here PAY-3313</>;
  if (view === 'EDIT') return <>Autopay Enrollment Add/Edit Here PAY-3316</>;
  return null;
};
